@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&family=Roboto&display=swap");
.notification_container {
  height: 90vh;
  width: 22.5vw;
  border-radius: 8px; 
  box-shadow: 0px 4px 8px 0px rgba(9, 11, 25, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.box1{
  width: 100%;
  height: 90%;
}


.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.wr_box1 {
  font-size: 1.7vw;
  color: #474747;
  font-weight: 400;
  font-style: bold;
  padding-top: 1.9vh;
  padding-left: 0.8vw;
  font-family: "Poppins", sans-serif;
}

.wr_box2 {
  font-size: 0.8rem;
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 0.8vh;
  padding-right: 0.8vw;
  cursor: pointer;
}
.wr_box2:hover {
  color: #0084ef;
}

.button {
  display: flex;
  gap: 0.9vw;
  margin: 24px 16px 0 16px;
  border-bottom: 2px solid #ededed;

  height: 3.6vh;
}

.button_container {
  height: 3.6vh;
  cursor: pointer;

  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.button_container.active {
  border-bottom: 2px solid #0073ea;
}

.button_inner {
  padding: 8px 16px;
  border-radius: 8px;
  color: #212121;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  height: 3.4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
}
.button_inner.active {
  background-color: #f2f2f2;
}
.searchBar {
  width: 100%;
  height: 3vh;
  margin-top: 0.5rem;
  padding: 0 1rem 0 1rem;
}
.inner_search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #ededed;
}
.notification_cards_container {
  height: 85%;
  width: 100%;
  display: flex;
}
.notification_cards {
  margin-top: 10px;
    width: 100%;
    overflow-y: scroll;
  max-height: 100%;
  padding: 0 5px 0 5px;
  text-align: center;
}

.load_more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem ;
  background-color: white;
}
.load_button {
  padding: 10px;
  border: none;
  background: none;
  color: #0084ef;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: underline;
  position: fixed;
}
.load_button:hover {
  color: #0083efb9;
}
