.App {
  max-width: 100vw;
  overflow: hidden;
  user-select: none;
}

.form-outline .form-control.active ~ .form-label,
.form-outline .form-control:focus ~ .form-label,
.inpactive .form-control ~ .form-label {
  background-image: linear-gradient(180deg, #fff, #fff 10%, transparent 90%, transparent);
  padding-top: 0 !important;
  -webkit-transform: translateY(-0.7rem) translateY(0.1rem) scale(0.8) !important;
  transform: translateY(-0.7rem) translateY(0.1rem) scale(0.8) !important;
  z-index: 1;
}
.tooltip-custom{
  background-color: black;
  color: white;
}
.signinup .form-outline .form-control.active ~ .form-label,
.form-outline .form-control:focus ~ .form-label,
.inpactive .form-control ~ .form-label {
  background-image: linear-gradient(180deg, transparent, white 20%, transparent 90%, transparent);
  padding-top: 0 !important;
}
.tooltip-custom{
  background-color: black;
  color: white;
}
.floating_btn {
  width: 40px;
  height: 40px;
  background: #003566;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  color: #ffffff;
  font-size: 40px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 20px;
  bottom: 20px;
  transition: background 0.25s;
  outline: blue;
  border: none;
  cursor: pointer;
}

#myid {
  background-image: linear-gradient(180deg, #fff, #fff 10%, transparent 90%, transparent);
  padding-top: 0 !important;
  -webkit-transform: translateY(-0.7rem) translateY(0.1rem) scale(0.8) !important;
  transform: translateY(-0.7rem) translateY(0.1rem) scale(0.8) !important;
  z-index: 1;
  background-image: linear-gradient(180deg, transparent, white 20%, transparent 90%, transparent);
  position: absolute;
  top: 0;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0.75rem;
  padding-top: 0.37rem;
  pointer-events: none;
  transform-origin: 0 0;
  transition: all 0.2s ease-out;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
}
#sidenav__items__main {
  animation: anim 0.5s forwards;
}

#sidenav__items__main__sub {
  animation: anim1 0.5s forwards;
}
@keyframes anim {
  0% {
    width: 4rem;
  }

  100% {
    width: 0rem;
  }
}
@keyframes anim1 {
  0% {
    width: 0rem;
  }

  100% {
    width: 4rem;
  }
}

@media (max-width: 768px) {
  .droppopmodal .modal-dialog {
    margin: 0;
    align-items: end !important;
    height: 100%;
    transform: translateY(100vw) !important;
  }

  .droppopmodal .modal-content {
    border-end-start-radius: 0;
    border-end-end-radius: 0;
  }

  .droppopmodal.show .modal-dialog {
    transform: none !important;
  }
}

.quoEmail {
  width: 100%;
  padding: 0.5rem 0.35rem;
  border-radius: 4px;
  outline: 1px solid #dfdfdf;
  border: none;
  margin: 0.75rem 0;
}

/* .btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
} */

.btn-primary {
  border: none !important;
  box-shadow: none !important;
}

.dropdown-toggle:after {
  border: none !important;
  content: none !important;
}

#sidenav_items_main {
  animation: anim 0.5s forwards;
}

#sidenav_items_main_sub {
  animation: anim1 0.1s forwards;
}

@keyframes anim {
  0% {
    width: 4rem;
  }

  100% {
    width: 0rem;
  }
}

@keyframes anim1 {
  0% {
    width: 0rem;
  }

  100% {
    width: 4rem;
  }
}

.main_cont {
  position: fixed;
  top: 0;
  width: 100%;
}

.example::-webkit-scrollbar {
  display: none;
}

.flagPhoneNumberInput {
  border: 1px solid #999;
  height: 3.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 12px;
}
.flagPhoneNumberInput .PhoneInput {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
}
.flagPhoneNumberInput .PhoneInputInput {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  outline: none !important;
}

.flagPhoneNumberInputErr {
  border: 1px solid red !important;
  height: 3.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 12px;
}
.flagPhoneNumberInputErr .PhoneInput {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
}
.flagPhoneNumberInputErr .PhoneInputInput {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  outline: none !important;
}
.flagPhoneNumberInputErr .PhoneInputInput::placeholder {
  color: #B3261E !important;
}

.error{
  color: #B3261E !important;
  font-size: 12px;
  font-weight: 500;
  padding-left: 0.8rem;
  padding-top: 0.2rem;
}


.carousel-indicators {
  display: none;
}