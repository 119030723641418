.createProjectModalHeader {
  display: flex;
}
.formName {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 12px; */
  margin-bottom: 3%;
}

.cancel {
  
  border-radius: 8px;
  background: #FFFFFF;
  border: none;
  user-select: none;
  padding:8px 24px;
  left: 879px; 
  top: 770px;
  border: 1px solid rgba(173, 181, 189, 0.60);

  border-radius: 4px;
  color: #888888;
  /* border: 2px solid red; */
}
.discardCloseBtn{
  display: flex;
  position: relative;
  /* left:60%; */
}
.closeBtn {
  display: flex;
  position: relative;

  /* left: 137%; */
}
.areaDescription{
  color: #454545;

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
letter-spacing: 0.5px;
}
.areaUnit {
  color: #454545;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
}
.createModalClose {
  border-radius: 0.5rem;
  border: none;
  background: none;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.createModalSave {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
