  @import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap");
.notify_card {
  display: flex;
  cursor: pointer;
  border-bottom: 2px solid #4c4d527b;
  width: 100%;
  /* height: 10vh; */
  /* margin-bottom: 10px;
  margin-top: 6px; */
  
  
  
}
.notify_card.active{
  background-color:#CCE5FF;
}
.notify_card.active:hover{
  background-color:#F2F2F2;
}
.notify_card:hover {
  background-color: #f2f2f2;
}
.cardwrapper {
  display: flex;
  width: 100%;
  /* background-color: aqua; */
  /* padding: 8px; */
  height: 100%;
  /* justify-content: space-between; */

}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20%;
  padding-top: 4%;
}


.img_wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #0084EF;
  height: 35px;
  width: 35px;
  border-radius: 50%;

}
.img1 {
 height: 30px;
 width: 30px;

}
.textfee {
  display: flex;
  flex-direction: column;
  padding: 0.7rem 0 0.5rem 0;
  justify-content: space-between;
  width: 80%;
  height: 100%;
}

.notificaition_headingfee {
  color: #323338; 
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 95%;
  height: auto ;
  text-overflow: ellipsis;
  overflow: hidden; 
  text-align: left !important;
  display: flex;

}
.notification_detailfee {
  color: #676879;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  height: 100%;
}
.notification_detail2fee {
  color: #676879;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left !important;
  height: 100%;
  width: fit-content;
  padding-right: 5px;
}
.detailsfee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 30%;
  margin-top: 3%;
 
}



@media (max-width: 1024px) {
  /* Styles to apply when the minimum height condition is met */
  .img_wrap{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  }
  .detailsfee{
    margin-top: 0.5rem;
  }

}
