.container {
  height: 100vh;
  display: flex;
}
.first_contain {
  background-color: #e9effb;
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* border: 2px solid red; */
}

.ImgDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e9effb;
}

.imgg {
  width: 95%;
}

.heading {
  color: #1d3557;
  font-family: "Inter";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 127.523%;
  /* margin-top: 2rem; */
}

.para {
  color: #212121;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon {
  margin-top: 2rem;
  height: 1.5rem;
  width: 1.5rem;
}

/* second div */
.second_contain {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.PersonalContent_div {
  /* border: 2px solid red; */
  width: 80%;
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.head_para {
  color: #212121;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.personal_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 44px;
  color: #212121;
  /* border:2px solid red; */
}

.inputs {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 1.5rem;
  color: #565353;
  font-size: 16px;
}

.inputsDrop {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
  width: 80%;
  margin-top: 1.5rem;
  font-size: 16px;
}

.inputsErr {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 1.5rem;
  border: 2px solid rgb(190, 48, 48);
  font-size: 16px;
}
.inputsErrDrop {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
  width: 80%;
  margin-top: 1.5rem;
  border: 2px solid rgb(190, 48, 48);
  font-size: 16px;
}
.inputs::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4d4d4d;
  font-size: 16px;
}
.btn {
  background: #2053c5;
  border-radius: 10px;
  border: none;
  padding: 0.9rem 1rem 0.9rem 1rem;
  width: 80%;
  margin-top: 2rem;
  color: #ffffff;
}
.backBtn {
  background: #f6f8fe;
  border: none;
  border-radius: 14px;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2053c5;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.backArrow {
  margin-right: 0.5rem;
}

.skip {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #b7b8bb;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1rem;
}

.nextBtn {
  width: 100%;
  padding: 15px 10px;
  color: #ffffff;
  background-color: #2053c5;
  border-radius: 12px;
  border: none;
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.addMoreBtn {
  color: #6b6a68;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.insideCaraouselItem{
display: flex;
flex-direction: column;
gap: 0.5rem;
}

.miniImg{
  height: 2.3rem;
   width: 2.3rem;
  margin-top: 1.5rem;
   margin-bottom: 1rem;
}

.CarouselItem{
  box-shadow: none;
  border-bottom: none;
}

.getItemOnboard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logoImg{
  height: 2rem;
  position: absolute;
  top: 2rem;
  left: 4rem;
}

.textAreaWrapper{
display:flex;
width:60%;
}
.emailWrapper{
  width:40%;
}
.wrapper{
  display:flex;
}
.addLater{
  display:none;
}
.inputWrapper{
  display: flex;
   flex-direction: column 
}
.roleWrapper{
  display:flex;
}
.forDiv{
  width: "40%";
}
.addBtnInLast{
  display:block;
}
@media (max-height: 600px) {
  .imgg {
    width: 70%;
  }
}

@media (max-width:767px){
  .addBtnInLast{
    display:none;
  }
  .container{
    flex-direction: column;
    overflow-y: scroll;
  }
  .first_contain{
    width:100%;
    flex-direction: column;
  }
  .miniImg{
    display: none;
  }
  .heading{
    font-size:14px;
  }
  .para{
    font-size: 10px;
    max-width:137px;
  }
 
  .backBtn{
    display:none;
  }
  .personal_head{
    font-size: 20px;
    line-height: normal;
  }
  .head_para{
    line-height: normal;
    font-size: 12px;
  }
 .getItemOnboard{
  margin-top: 16px;
}
  .second_contain{
    width:100%;
    justify-content: unset;
    padding-left: 16px; 
  padding-right:16px;
 }
  .nextBtn{
    border-radius:0;
    font-size: 16px;
    padding:12px;
  }
  .flexForCarousel{
    display: flex;
    padding-bottom: 44px;
    padding-top: 16px;
  }
  .logoImg{
    position:static;
    top: 0rem;
  left: 0rem; 
  width: 125.385px;
height: 20px;
  }
  .logoImgContainer{
    background-color: white;
    width:100%;
    padding:13px 0px 15px 16px;
  }
  .insideCaraouselItem{
    padding-top:12px;
  }
  .wrapper{
    flex-direction: column;
    gap:16px;
  }
  .textAreaWrapper{
    width:100%;
    /* justify-content: space-between; */
    gap:16px;
  }
  .emailWrapper{
    width:100%;
  }
.PersonalContent_div {
  width:100%;
}
.addLater{
  display:block;
}
.skip{
  font-size: 14px;
  line-height: 18px;
  font-weight:500;
  margin:0;
}
.inputWrapper{
  gap:10px;
  border-radius: 10px;
  border: 0.25px solid #999;
background: #FAFAFA;
padding: 12px;
}
.inviteButton{
 position: relative;
    bottom:10px;
}
.roleWrapper{
  gap:16px;
}
.forDiv{
  display: none;
}
}

@media(min-width:320px) and (max-width:349px){
  .imgg{
    width: 153px;
  height: 89px;
  }
  .skip{
    font-size: 13px;
  }
  .personal_head{
    font-size: 18px;
    line-height: normal;
  }
}

@media (min-width:350px) and (max-width:767px){
  .imgg{
    width: 163px;
  height: 99px;
  }
}