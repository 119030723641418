.leftPanelContainer {
  width: 60%;
  padding: 0 0.5rem 0 1rem;
  height: 100%;
  overflow-y: scroll;
}
.leftPanelContainerMob {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  padding: 0 0.5rem 0 1rem;
  overflow-y: scroll;
}
.leftPanelContainer::-webkit-scrollbar {
  display: none;
}
.rightPanelContainer {
  width: 40%;
  padding: 0 1rem 0 0.5rem;
  height: 100%;
  overflow-y: scroll;
}
.rightPanelContainerMob {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 0 1rem 0 0.5rem;
  overflow-y: scroll;
}
.rightPanelContainer::-webkit-scrollbar {
  display: none;
}

.nameHeading {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  color: #3b5998;
  font-weight: 600;
}

.eachSection {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  justify-content: space-between;
}
.eachSection::after {
  display: none;
}

.actionButtons {
  display: flex;
  margin-bottom: 1rem;
}
.submitButtonContainer {
  width: 50%;
  padding: 0 0.5rem 0 0;
}
.previewButtonContainer {
  width: 50%;
  padding: 0 0 0 0.5rem;
}
.submitButtonContainer button {
  width: 100%;
  padding: 0.4rem;
}
.previewButtonContainer button {
  width: 100%;
  padding: 0.4rem;
}
.submitButton {
  background-color: #3b5998;
  color: #ffffff;
  border: 1px solid #3b5998;
  border-radius: 4px;
  font-size: 14px;
}
.previewButton {
  background-color: #ffffff;
  color: #3b5998;
  border: 1px solid #3b5998;
  border-radius: 4px;
  font-size: 14px;
}

.feesSummaryPart {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.eachAccordionHeader {
  color: #222222 !important;
}
.eachAccordionContent {
  color: #000000;
  font-size: 12px;
}

.customCheckBoxActive {
  border: 1px solid #3b5998;
  background-color: #3b5998;
  color: #ffffff;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customCheckBoxInactive {
  border: 1px solid #3b5998;
  background-color: #ffffff;
  color: #ffffff;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customSmallCheckBoxActive {
  border: 1px solid #3b5998;
  background-color: #3b5998;
  border-radius: 1px;
  height: 0.6rem;
  width: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-right: 0.3rem;
  cursor: pointer;
}
.customSmallCheckBoxInactive {
  border: 1px solid #3b5998;
  background-color: #ffffff;
  border-radius: 1px;
  height: 0.6rem;
  width: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-right: 0.3rem;
  cursor: pointer;
}

.summaryTableHeadings {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.eachTableHead {
  width: 25%;
  font-size: 10px;
  padding: 0 0.1rem;
  color: #888888;
}

.contentRow {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}
.eachTableData {
  width: 25%;
  padding: 0 0.1rem;
  font-size: 10px;
  text-align: end;
}
.eachTableData input {
  width: 90%;
}

.eachTotalSection {
  font-size: 11px;
  font-weight: 600;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.radioActive {
  border-radius: 50%;
  border: 2px solid #2cace2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2cace2;
  padding: 0.125rem;
}
.radioInactive {
  border-radius: 50%;
  border: 2px solid #a7a7a7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 0.125rem;
}

.amountInputField {
  width: 100%;
  background-color: #fafafa;
  border: none;
  border-bottom: 1px solid #dfdfdf;
  padding-left: 0.5rem;
  outline: none;
}
.amountInputField:focus {
  border-bottom: 1px solid #3b5998;
}

.lumpDiscountInput {
  width: 4rem;
}
.lumpDiscountInput input {
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #dfdfdf;
  background-color: #fafafa;
}
.lumpDiscountInput input:focus {
  border-bottom: 1px solid #3b5998;
}

.termsPayments{
  font-size: 11px;
  font-weight: 600;
  margin-top: 0.5rem;
  color: #3b5998;
  cursor: pointer;
}