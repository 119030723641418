.container{
    /* border:2px solid red; */
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 2px 2px 2px rgb(206, 204, 204); */
    box-shadow: 2px 2px 8px rgba(206, 204, 204, 0.2);
}

.headerImage{
    width: 11rem;
    z-index: 1;
    cursor: pointer;
}
.logo{
    height: 3rem;
}
@media(max-width:767px){
    .headerImage{
        width:10rem;
    }
}