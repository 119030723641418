.lmsmail .body p {
  margin-bottom: 0px;
}
.chipContainer {
  display: flex;
  align-items: center;
  overflow-x:auto;
  align-items: center;
  padding-left: 0;
  overflow-y: none;
  margin-bottom: 0;
  max-width: fit-content;
}
.chips {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  color: #000000;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 25px;
  border: 1px solid black;
  background: #fff;
}
.chipContainerEmail {
  display: flex;
  align-items: center;
  overflow-x:auto;
  align-items: center;
  padding-left: 0;
  overflow-y: none;
  margin-bottom: 0;
  max-width: fit-content;
  /* min-width: fit-content; */
}
.chipsEmail {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  color: #000000;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 25px;
  /* z-index: 12; */
  border: 1px solid black;
  background: #fff;
}
  

.labelTop {
  position: absolute;
  transform: translate(10px, -16px);
  background: white;
  /* z-index: 11; */
  font-size: 14px;
  /* margin-bottom: 10px; */
}
.chipContainer::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 10px;
  transition: all;
  transition-duration: 250ms;
}

/* Track */
.chipContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.chipContainer::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.chipContainer::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.chipContainer::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}