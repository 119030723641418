.activeTab {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.0105rem;
  border-radius: 4px;
  background: #f0f9ff;
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  align-items: center;
  gap: 0.5rem;
  transition: 0.5s all;
  cursor: pointer;
}
.xls_line {
  color: #b6bbcc;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.rightTabContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}
.xls_download {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #e0e3e6);
  background: var(--White, #fff);
  color: var(--Neutral-800, #40454a);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.xls_import {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-500, #4a68ff);
  background: var(--White, #fff);
  color: #0084ef;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.inactiveTab {
  border-radius: 4px;
  background: #fcfcfc;
  font-family: Inter;
  color: #666464;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0105rem;
  background: #fcfcfc;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: 0.5s all;
  cursor: pointer;
}
.import_csv {
  cursor: pointer;
}
.csv_text{
  color: #0165B6;

/* Text sm/Semibold */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
}
.export_csv {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-50, #f9f5ff);
  background: var(--Primary-50, #f9f5ff);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.tabs__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  /* width: 93vw; */
  position: fixed;
  /* margin-left: 14.5%; */
}
.tab__selected {
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid #0084ef;
  background: #fff;
  padding: 6px 16px;
  letter-spacing: 0.168px;
  display: flex;
  color: #0084ef;
  line-height: 16.94px;
  align-items: center;
  font-size: 14px;
}
.tabDim1 {
  height: 29px;
  width: 94px;
}
.tabDim2 {
  height: 29px;
  width: 160px;
  white-space: nowrap;
}
.tab__not__selcted {
  letter-spacing: 0.168px;
  display: flex;
  cursor: pointer;
  border-radius: 50px;
  line-height: 16.94px;
  align-items: center;
  border: 1px solid #eeeeef;
  background: #fff;
  padding: 6px 16px;
  font-size: 14px;
}
.leadManagementContainer {
}
.btn.active,
.btn:active {
  box-shadow: none;
}
.sideBarPanel {
  width: 14.5vw;
  border-right: 1px solid #eeeeef;
  padding: 16px;
  position: fixed;
  height: 100vh;
}
.unassignedButton {
  color: #212427;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  margin-right: 20px;
  letter-spacing: 0.168px;
}

@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .activeTab {
    font-size: 0.6rem;
  }
  .inactiveTab {
    font-size: 0.6rem;
  }
  .tab__selected {
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid #0084ef;
    background: #fff;
    padding: 4px 16px;
    letter-spacing: 0.168px;
    display: flex;
    color: #0084ef;
    line-height: 16.94px;
    align-items: center;
    font-size: 12px;
  }
  .tab__not__selcted {
    letter-spacing: 0.168px;
    display: flex;
    cursor: pointer;
    border-radius: 50px;
    line-height: 16.94px;
    align-items: center;
    border: 1px solid #eeeeef;
    background: #fff;
    padding: 6px 16px;
    font-size: 12px;
  }
  .tabDim1 {
    height: 29px;
    width: 90px;
  }
  .tabDim2 {
    height: 29px;
    width: 150px;
  }
}
