.main_cont {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}
.logo{
  height: 1.5rem;
}
.filterDropdownContainer{
  /* min-height: 400px; */
  max-height: 170px;
  overflow-y: scroll;
  width: 180px;
  word-break: break-all;

}
.headerfilterDropdownContainer{
  /* min-height: 400px; */
  max-height: 150px;
  overflow-y: scroll;
  width: 210px;
  word-break: break-all;
  left: 0px;
  
}
.filterDropdownText{
  color: #363535;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.144px;
margin-left: 0.6rem;
display: flex;
}
.mainContainer {
  display: flex;
  height: 3rem;
  align-items: center;
  background: #292929;
  box-shadow: 0px 2px 16px 0px rgba(154, 170, 207, 0.1);
  padding: 0rem 1.75rem;
}
.dropdownButton {
  display: flex;
  align-items: center !important;
  height: 32px;
  padding: 8px 12px 7px 12px;
  justify-content: space-between;
  align-items: flex-end;
  width: 9rem !important;
  /* gap: 81px; */
  border-radius: 8px;
  border: 1px solid #e9e9ec;
  background: #fff;
}
.headerDropdownButton{
  background: none;
  padding: 0px;
  outline: none;
  border: none;
}

.headerDropdownButton :hover{
  background-image: none;
  background-color: transparent;
  outline: none;
  border: none;
}
.buttonText {
  color: #a2a2a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.144px;
}
.font {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.012rem;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.font:hover{
  color: #fff;
}

.fontActive {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.012rem;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #fff;
  background: #3d3d3d;
  height: 100%;
  cursor: pointer;
}
.fontActive :hover{
  color: #fff !important;
}

.mainContainer2 {
  display: flex;
  height: 3.25rem;
  align-items: center;
  border-bottom: 1px solid #eeeeef;
  background: #fff;
  padding: 0rem 1.75rem;
  justify-content: space-between;
}

.mainContainer4 {
  display: flex;
  height: 10rem;
  align-items: center;
  border-bottom: 1px solid #eeeeef;
  background: #fff;
  padding: 0rem 1.75rem;
  justify-content: space-between;
  transition: 0.5s ease-out;
}

.mainContainer3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  width: 4rem;
  border-right: 1px solid #eeeeef;
}

.fontDetails {
  color: #1a1a1a;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.eachItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* gap: 0.625rem; */
}

.eachItemActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #d6eeff;
  /* gap: 0.625rem; */
}

.routing__btns {
  width: 3rem;
  display: flex;
  justify-content: space-between;
  /* margin-left: 1rem; */
}

.name__section {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.project__name {
  margin: 0rem 0.5rem;
  color: #66737f;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.012rem;
  cursor: pointer;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  margin-right: 0;
}

.none {
  display: none;
}

/* .project__name:hover .none {
    display: block;
}

.project__name:hover {
    background: #f4f4f4;
    padding: 0.25rem;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
} */

.project {
  margin-left: 0.25rem;
  color: #212529;
  font-family: Inter;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.012rem;
  cursor: default;
}

.filter_icon {
  color: #6f6e6e;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0105rem;
  padding: 0 0 0 0.5rem;
  margin-bottom: 0.15rem;
}

.search_inactive {
  display: flex;
  width: 11.875vw;
  height: 2.185rem;
  /* padding: 0.5rem 7.5rem 0.5rem 0.75rem; */
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #fafafa;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  cursor: pointer;
}

.search_active {
  display: flex;
  width: 11.875vw;
  height: 2.185rem;
  /* padding: 0.5rem 7.5rem 0.5rem 0.75rem; */
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #fafafa;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  cursor: pointer;
}

.trans-class {
  transition: 0.5s all;
}

.sidepanel_active {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.0105rem;
  border-radius: 0.25rem;
  background: #f0f9ff;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  gap: 12px;
  padding-left: 0.75rem;
  transition: 0.5s all;
  cursor: pointer;
}

.sidepanel_inactive {
  border-radius: 0.25rem;
  background: #fcfcfc;
  font-family: Inter;
  color: #666464;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0105rem;
  background: #fcfcfc;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.75rem;
  transition: 0.5s all;
  cursor: pointer;
}

.updates {
  border-radius: 3.125rem;
  background: #fff;
  color: #0084ef;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0075rem;
  padding: 2px;
}

.clear {
  color: #a8a8a8;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.0105rem;
  /* margin-right: 0.75rem; */
  cursor: pointer;
  height: "2.185rem",
}

.dropdown {
  background: #ffffff;
  text-align: left;
  box-shadow: 0px 2px 16px 0px #9aaacf33;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.dropdownItem {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 6px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  /* background-color: red; */
}

.dropdownItem:hover {
  background-color: #00000021;
  /* padding-left: 50px important;  */
}

/* Notification */
.notifyContainer {
  /* border: 2px solid red; */
  /* position: absolute; */
  top: 10%;
  right: 3%;
  height: 88vh;
  width: 22vw;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.notification_modal {
  width: 22vw;
  left: 27%;
  top: 1.6%;
}

.headingContainer {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1d3557;
  border-radius: 8px 8px 0px 0px;
  color: white;
  height: 4rem;
}

.notify_pop{
  position: absolute;
  /* width: 22vw;
  left: 27%;
  top: 1.6%; */
  top: 3rem;
  right: 12rem;
  z-index: 999999999;
}
.notifyHeading {
  /* border: 2px solid red; */
  padding-left: 1rem;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 29px;
  color: #ffffff;
  padding-top: 2%;
  /* width: 15v	w; */
}

.readUnreadHead {
  /* border: 2px solid red; */
  padding-right: 1rem;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  padding-top: 2%;
  font-size: 11px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
}

.adminName {
  /* border: 2px solid red; */
  height: 70vh;
  overflow-y: scroll;
}

.singleAdmin {
  /* border:2px solid grey; */
  display: flex;
  height: auto;
  /* align-items: center; */
  border-bottom: 0.441176px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.bellstyle {
  /* margin-top: 1rem; */
  border: none;
  background-color: #ffffff;
}

.bellIcon {
  /* border: 2px solid red; */
  background-color: white;
  color: #3b5998;
  font-weight: bold;
  font-size: 1.4rem;
  /* margin: 0.3rem; */
  cursor: pointer;
}

.profile {
  /* border: 2px solid red; */
  color: #3b5998;
  font-size: 2rem;
  padding-left: 4%;
}

.adminAndStatus {
  /* border: 2px solid red; */
  /* width: 15vw; */
  display: flex;
  flex-direction: column;
  padding: 0.6rem;

  /* margin-top: 0.8rem; */
}

.taskname {
  /* border: 2px solid red; */
  font-size: 0.8rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #212121;
  width: 16.5vw;
  justify-content: left;
  padding-bottom: 0.4rem;
}

.status {
  /* border: 2px solid red; */
  font-size: 0.7rem;
  /* width: fit-content; */
  display: flex;
  justify-content: space-between;
  color: #888888;
}

.projectName {
  width: 7vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seeAll {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d3557;
  cursor: pointer;
}

.unreadCount {
  border-radius: 100%;
  font-size: 0.5rem;
  color: white;
  background-color: #3b5998;
  width: 20px;
  height: 20px;
  padding: 1px;
  position: absolute;
  margin-left: 11px;
  text-align: center;
  font-weight: 700;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.recent {
  color: #d1d0d0;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.009rem;
  padding: 0.15rem 1rem;
}

.all {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.009rem;
  padding: 0.15rem 1rem;
}
@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .dropdownButton {
    /* gap: 40px; */
  }
}