.nextBtn {
  background-color: #3b5998;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  user-select: none;
  width: 100px;
  height: 39px;
  left: 879px;
  top: 770px;
  background: #3b5998;
  border-radius: 4px;
  color: white;
  /* border: 2px solid red; */
}
.inputBox {
  line-height: 30px;
  font-size: 16px;
}
.addressBTN {
  background-color: #3b5998;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  user-select: none;
  width: auto;
  height: 33px;
  padding: 5px 14px;
  left: 879px;
  top: 770px;
  background: #3b5998;
  border-radius: 4px;
  color: white;
  margin-left: 1vw;
  /* border: 2px solid red; */
}
.nextBtn2 {
  background-color: grey;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  user-select: none;
  width: 100px;
  height: 39px;
  left: 879px;
  top: 770px;
  background: rgb(174, 172, 172);
  border-radius: 4px;
  color: white;
}
.clientCardHeader {
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
}

.detailsHead {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding-left: 1rem;
  padding-top: 1rem;
}

/* .inputField input {
  width: 100%;
  border-radius: 8px;
  height: 2.5rem;
  padding: 1rem;
  border: 1px solid #dfdfdf;
  border: 2px solid red;
} */

.modalBodyScrollable {
  overflow-y: scroll;
  /* border: 2px solid red; */
}
.closeBtn {
  display: flex;
  position: relative;
  left: 137%;
}
.formName{
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 3%;
}
.discardCloseBtn{
  display: flex;
  position: relative;
  /* left:60%; */
}

.formCheckbox{
  display: flex;
  /* justify-content: space-around; */
  margin-left: 2%;
  width: 100%;
  margin-bottom: 3%;
}
.createModalSave {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  background: #0084ef;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.createModalClose {
  border-radius: 0.5rem;
  border: none;
  background: none;
  display: flex;
  height: 2.1875rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.createProjectModalHeader {
  display: flex;
}

.modalContinueActive {
  background-color: #3b5998;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  user-select: none;
  width: 100px;
  height: 39px;
  left: 879px;
  top: 770px;
  background: #3b5998;
  border-radius: 4px;
  color: white;
  /* border: 2px solid red; */
}

.modalContinueInactive {
  /* padding: 0.5rem 1.5rem; */
  border: none;
  user-select: none;
  pointer-events: none;
  width: 100px;
  height: 39px;
  left: 879px;
  top: 770px;
  background: #3b5998;
  border-radius: 4px;
  color: white;

  /* border: 2px solid red; */
}

.inputSection {
  user-select: none;
  /* border: 2px solid green; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline: none;
}

.inputSection div {
  font-size: 16px;
  color: #a7a7a7;
  width: 9rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #323232;
  /* border: 2px solid red; */
}

.inputSection input {
  /* height: 2.5rem;
  width: 20rem; */
  width: 381px;
  height: 37px;
  background: #fafafa;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  margin: 0.6rem;
  padding-left: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* color: #888888; */
  /* border: 2px solid red; */
}
.uploadProof {
  width: 20vw;
  margin-left: -23vw;
  display: flex;
  flex-direction: column;
}
.upload {
  height: 40px;
  width: 40px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15px; */
}
.upload > div {
  top: 0%;
  left: 0%;
  font-size: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5255a4;
}
.upload > input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.createNewFooter {
  display: flex;
  flex-direction: row;
}

.ant-modal .ant-modal-content {
  padding: 50px;
}

.custom-modal .ant-modal-header {
  background-color: black !important;
}
