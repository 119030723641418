.loginIcon {
  max-width: 20px;
}

.jap {
  font-weight: 500;
  font-size: 14px;
  color: #49b7cf;
  border: 1px solid #49b7cf;
  height: 38px;
  border-radius: 4px;
  width: 10rem;
}

.jap:hover {
  /* color: #49b7cf; */
}

@media (max-width: 770px) {
  /* .jap {
    display: none;
  } */

  .signiIn {
    font-size: 12px;
  }

  .header-off {
    padding: 1.25rem 1rem 0rem 1rem;
  }

  /* .navbar > .container-fluid {
    justify-content: start !important;
  } */
  /* nav .pi.navbar-brand img {
    height: 18px !important;
    width: 122px !important;
    margin-left: 36px !important;
  } */
}

.hover-div > a:hover {
  /* background: #f0f7f9; */
}

@media (min-width: 1020px) {
  .blue-bottom-line {
    position: relative;
  }

  .blue-bottom-line::before {
    content: "";
    border-bottom: 3px solid #49b7cf;
    position: absolute;
    width: 90%;
    bottom: -13px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
    padding: 10px;
    flex-basis: 40%;
    flex-grow: 1;
    align-items: center;
    top: 0;
    position: relative;
    height: 100%;
    z-index: 99;
    background: none;
    left: 0;
    border-radius: 0px 40px 40px 0px;
    min-width: 100%;
  }

  .navbar-collapse.collapsing {
    height: 0;
    transition: 1s;
  }

  /* nav .navbar-brand {
    transform: scale(1.5);
  } */

  /* .navbar > .container-fluid {
    justify-content: space-between !important;
  } */
  .loginIcon {
    max-width: 37px !important;
  }
}

nav .pi.navbar-brand {
  max-width: 249px;
}

nav .pi.navbar-brand img {
  height: 27px;
  width: 162px;
}

.custom-header header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px 20px;
  background: white;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
}

.custom-header header .logo {
  /* color: #FFF; */
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  float: left;
  font-weight: bold;
}

.custom-header header .nav-cover {
  /* float: right; */
}

.custom-header header nav ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.custom-header header nav ul li {
  list-style: none;
}

.custom-header header nav ul li a {
  height: 50px;
  line-height: 50px;
  padding: 0 0px;
  margin: 0 30px; 
  text-decoration: none;
  display: block;
  font-size: 15px !important;
}

.custom-header header nav ul li a:hover,
.custom-header header nav ul li a.active {
  /* color: #FFF; */
  /* background: rgb(216, 216, 216); */
}

@media screen and (max-width: 350px) {
  .header-off {
    padding: 1.25rem 1rem 0rem 1rem;
  }
}

.custom-header .menu-toggle {
  /* color: #FFF; */
  float: left;
  line-height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

#toggle {
  width: 21px;
  margin: 0px;
  transition: all 0.3s;
}

#toggle.on {
  /* margin-top: -9px; */
}

#toggle div {
  width: 100%;
  height: 2px;
  background: #000;
  margin: 4px 0px;
  transition: all 0.3s;
  backface-visibility: hidden;
}

#toggle .two,
#toggle .zero {
  width: 80%;
}

#toggle.on .one {
  transform: rotate(45deg) translate(5px, 5px);
}

#toggle.on .two,
#toggle.on .zero {
  opacity: 0;
}

#toggle.on .three {
  transform: rotate(-45deg) translate(3px, -4px);
}

.nav-item {
  position: relative;
}

.down-blue-arrow {
  border-width: 0px 2px 2px 0px;
  border-top-style: initial;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: black;
  border-bottom-color: black;
  border-left-color: initial;
  border-image: initial;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  margin-top: -5px;
}

.right-blue-arrow {
  border-width: 0px 2px 2px 0px;
  border-top-style: initial;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: black;
  border-bottom-color: black;
  border-left-color: initial;
  border-image: initial;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  margin-top: -5px;
}

.hoverdiv:hover > a {
  /* color: #49b7cf !important; */
  background: #f0f7f9;
}

.hoverdiv > a > .down-blue-arrow {
  /* transition: 0.5s; */
}

.hoverdiv:hover > a > .down-blue-arrow {
  transform: rotate(-135deg);
  margin-top: 7px;
}

.hoverdivactive {
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
}

.hoverdiv:hover > a > .hoverdivactive,
.hoverdivactive:hover {
  width: auto;
  /* height: auto; */
  z-index: 1;
  opacity: 1;
  transition: all 0.5s;
  display: block !important;
}

.hoverdivactive:hover > div > div > a:hover > span {
  /* color: #49b7cf !important; */
  /* background: #f0f7f9; */
}

.h-link {
  height: 40px !important;
}

.nav-link{
  font-size: 18px !important;
}
.long-nav-panel {
  /* left: 100%; */
  /* height: 80vh; */
  /* height: 34.25vh; */
  height: 17.25rem;
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.long-nav-panel2 {
  /* left: 100%; */
  /* height: 80vh; */
  height: 22rem;
  /* width: 40vw; */
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.professiondrop {
  /* width: 34vw; */
  width: 34.5rem;
  height: 100%;
  /* display: flex;
  flex-wrap: wrap; */
}

.findProflinebreak {
  height: 100%;
  width: 1px;
  color: #dfdfdf;
  margin: 0.5rem 0;
}

.signIn {
  background: #49b7cf;
  border-radius: 4px;
  width: 6rem;
  height: 38px;
  color: white;
  font-size: 14px;
  /* font-size: .75rem; */
}

.designideanewdiv {
  top: 100%;
  left: -80%;
  /* top: 3.25rem; */
  /* left: -3rem; */
  /* padding-top: 14px; */
  /* width: 44vw !important; */
  width: 47.5rem !important;
  /* z-index: -1; */
  /* background: white; */
}

@media (max-width: 991px) {
  #head-nav {
    overflow: hidden;
  }

  .custom-header header {
    padding: 6px 20px;
  }

  .custom-header .menu-toggle {
    display: flex;
  }

  .custom-header header nav {
    position: absolute;
    width: 100%;
    /* width: 89%; */
    /* height: calc(100vh - 50px); */
    height: auto;
    background: #fff;
    top: 0px;
    left: -100%;
    transition: 0.5s;
  }

  .custom-header header #head-nav {
    top: -300px;
    left: 0;
  }

  .custom-header header #head-nav.active {
    top: 59px;
    left: 0;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%) !important;
    /* border-radius: 0px 8px 28px 0px; */
  }
  .custom-header header #head-nav {
  }
  .custom-header header #head-nav2,
  .custom-header header #head-nav2 ul {
    top: 0px;
    height: 100vh;
  }
  .custom-header header #head-nav2 {
    background-color: transparent;
    transition: 0.5s;
  }
  .custom-header header #head-nav2.active {
    top: 0px;
    left: 0;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%) !important;
    background-color: rgba(0, 0, 0, 0.176) !important;
    /* border-radius: 0px 8px 28px 0px; */
  }
  .custom-header header nav ul {
    display: block;
    text-align: start;
  }

  .custom-header header nav ul li a {
    /* border-bottom: 1px solid rgba(0,0,0,0.2); */
  }

  .blue-bottom-line {
    position: relative;
    background-color: rgba(23, 96, 145, 0.15);
  }

  /* .blue-bottom-line::before {
    content: "";
    border-bottom: 3px solid #49b7cf;
    position: absolute;
    width: 90%;
    bottom: -13px;
  } */
}
