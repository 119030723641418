.mainContainer {
  display: grid;
  gap: 1rem;
}
.card {
  display: flex;
  border-radius: 12px;
  border: 1px solid #ededed;
  padding: 0.5rem;
  background: #fff;
}
.cardHeader {
  display: flex;
}
.cardBody {
  padding-left: 1rem;
}
.cardSubBody {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.buttonText {
  color: #a2a2a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.144px;
}
.dropdownButton {
  display: flex;
  align-items: center !important;
  height: 32px;
  padding: 8px 12px 7px 12px;
  justify-content: space-between;
  align-items: flex-end;
  width: 17vw !important;
  /* gap: 81px; */
  border-radius: 8px;
  border: 1px solid #e9e9ec;
  background: #fff;
}
.filterDropdownContainer {
  /* min-height: 400px; */
  max-height: 170px;
  overflow-y: scroll;
  word-break: break-all;
  min-width: 200px;
}
.filter_icon {
  color: #6f6e6e;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0105rem;
  padding: 0 0 0 0.5rem;
  margin-bottom: 0.15rem;
}

.imageRotateCssToRight {
  transform: rotate(-90deg);
}
.inputBoxStyle {
  width: 17vw !important;
  border-radius: 8px;
  height: 32px;
  padding: 8px 12px 7px 12px;
  border: 1px solid #e9e9ec;
  outline: 1px solid #e9e9ec;
}

.buttonContainer {
  justify-content: end;
  display: flex;
  gap: 1rem;
}
.buttonStyle {
  border-radius: 8px;
  border: 1px solid #0084ef;
  background: #0084ef;
  width: fit-content;
  display: inline-flex;
  height: 42px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}
.buttonStyle span {
  color: white;
}
.buttonStyle:hover{
  cursor: pointer;
}
.pdfTopImage{
  width: 100%;
}
.tableContainer table{
  width: 100%;
  margin: 2rem 0rem;
}
.tableContainer th{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.tableContainer td{
width: 40%;
border: 1px solid #dddddd;
text-align: left;
padding: 8px;
}
.hidden_on_webpage{
  display: none;
}
/* @media print {
  .hidden_on_webpage {
    display: block !important;
  }
} */