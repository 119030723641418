.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);
  width: 100vw;
  background-image: url(../../Assets/bg-main.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.box {
  width: 40%;
  border: 1px solid #e0e3e6;
  border-radius: 12px;
  background-color: #ffffff;
}

.getotpBtn {
  color: #fff;
  font-family: "Inter";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #0084ef;
  background: #0084ef;
  width: 100%;
  padding: 10px 14px;
}

.para {
  color: #999;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.resetPassword{
  padding: 24px;
  border-bottom: 1px solid #E0E3E6;
  color: #40454A;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.resetPasswordContainer{
  padding: 24px 24px 28px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

@media(max-width:767px){
  .box{
    width:90%;
    margin-top: auto;
    margin-bottom: auto;
    position:fixed;
  }
  .resetPassword{
    padding:20px;
    font-size:24px;
    line-height: normal;
  }
  .para{
    font-size:12px;
    line-height: 18px;
  }
  .resetPasswordContainer{
    padding:20px;
    gap:1.25rem;
  }
  .getotpBtn{
    padding:14px 10px;
    font-size: 14px;
  }
}