#body,
body {
  background: white !important;
}
.basic-single{
  width: 100% !important;
}
.qscb::-webkit-scrollbar {
  width: 5px;
  background: #f3f3f3;
}

/* Track */
.qscb::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.qscb::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
.qscb::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

.qscb::-webkit-scrollbar-thumb:focus {
  background: #bebebe;
}

.noafter::after {
  content: "";
  display: none !important;
}

.accordion-item.active {
  border: 1.5px solid #174e86 !important;
}

.accordion-item.active .accordion-header {
  background: #ebeef5 !important;
  /* Primary Color/Teal */
}

.accordion-item.active input[type="checkbox"]:checked {
  border-color: #174e86;
  background-color: #174e86;
}

.done {
  width: 60px;
  height: 60px;
  position: relative;
  /* left: 0;
	right: 0; */
  /* top:-20px; */
  margin: auto;
}

.circ {
  opacity: 0;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.leadStatushover{
  width: 90%;
  margin-left: 5%;
}
.leadStatushover:hover{
border-radius: 5px !important;
width: 90%;
margin-left: 5%;
cursor: pointer;
}
.leadStatushovercontaineer{
  padding-top: 8px;
  padding-bottom: 8px;
}
.tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  -webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
  -moz-transition: stroke-dashoffset 1s 0.5s ease-out;
  -ms-transition: stroke-dashoffset 1s 0.5s ease-out;
  -o-transition: stroke-dashoffset 1s 0.5s ease-out;
  transition: stroke-dashoffset 1s 0.5s ease-out;
}

.drawn svg .path {
  opacity: 1;
  stroke-dashoffset: 0;
}

/* .blue-bottom {
  border-bottom: 3px solid #174e86;
} */
.blue-bottom,
.blue-bottom-n {
  position: relative;
}

.blue-bottom-n::before {
  content: "";
  border-bottom: 3px solid #174e86;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -8px;
}

.blue-bottom::before {
  content: "";
  border-bottom: 3px solid #174e86;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.modal-backdrop {
  z-index: auto;
}

.freeUserNotice {
  background: linear-gradient(89.98deg, #faf8ff 0.71%, #d5e2ff 99.99%);
  border-radius: 4px;
}

.fixed-tb table,
.tr-tb table {
  margin: 0;
}

.fixed-tb table thead {
  position: sticky;
  top: 0px;
  /* z-index: 1; */
}

/* .resize-vertical {
  resize: vertical;
} */

.space {
  border-collapse: separate;
  border-spacing: 0 11px;
}

.tableHeader th{
/* background-color: #008000;
color: #0084EF; */
/* color: #008000;
width: inherit; */
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.stripe:before {
  content: "";
  background-color: transparent;
  height: 100%;
  width: 6px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px 0px 0px 5px;
}

.interested:before {
  background-color: #3b5998 !important;
}

.not_interested:before {
  background-color: #ab4cf5 !important;
}

.followUp:before {
  background-color: #49b7cf !important;
  display: none;
}

.no_response:before {
  background-color: #ed673c !important;
}

@media (min-width: 767px) {
  .suggestedQuoDes {
    max-height: 50vh;
    -y: scroll;
    overflow-x: hidden;
  }

  .needArea {
    max-height: 180px;
    overflow-y: auto;
  }
}

@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .pfs-14{
    font-size: 12px !important;
  }
  .pfs-12{
    font-size: 10px !important;
  }
}

@media (max-width: 767px) {
  .quoitemimg {
    margin-left: -65px;
  }

  .specs {
    margin-top: -25px;
  }

  .respTick {
    transform: scale(0.5);
    position: relative;
    left: -12px;
    top: -6px;
  }

  .respTick ~ h6 {
    position: relative;
    left: -12px;
  }
}
.removeShoadow{
box-shadow: none;
}

@media (min-width: 767px) and (max-width: 1200px) {
  .boqsum {
    margin-bottom: 30px !important;
  }
}

@media (max-width: 1002px) {
  .boqsum {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .boqsum .show {
    border-radius: 25px 25px 0px 0px;
    overflow: hidden;
    border-top: 1px solid rgb(195, 195, 195);
  }
}

@media (min-width: 998px) {
  .das-main-box {
    /* margin-left: 20vw; */
    margin-top: 6rem;
    position: relative;
    height: 85vh;
    background: #f2edfd;
    height: calc(100vh - 4.5rem);
  }
}

@media (min-width: 1002px) {
  .quoview.das-main-box {
    margin-left: 100px;
  }

  .boqsum .accordion-header {
    pointer-events: none;
  }

  .boqsum .accordion-button::after {
    content: "";
    display: none !important;
  }
}

#quodf[contenteditable="true"] {
  background-color: white !important;
  padding: 0 12px;
  border: 1px solid black;
  border-radius: 5px;
}

.rateinp:focus {
  outline: none;
  border-bottom: 1px solid black;
}

.rateinp::-webkit-outer-spin-button,
.rateinp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.leadStatusDropdownbutton {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  padding: 6px 8px !important;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  /* font-size: 11px !important; */
}

.bg-no-response {
  background: rgba(69, 69, 69, 0.2);
  color: #454545;
}
.new-bg{
  background: #E5F4FF;
  color: #0084EF;
}

.bg-follow-up {
  background: #fef5eb;
  color: #f78508;
}

.bg-interested {
  background: rgba(0, 128, 0, 0.1);
  color: #008000;
}
.countBox {
  display: flex;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #fff;
}

.bg-not-interested {
  background: #FFF0F0;
  color: #B40000;
}

.bg-meeting-scheduled {
  background-color: #fbe6fd;
  color: #b200c1 !important;
}

.ant-modal-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
