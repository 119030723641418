.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 4rem);
    width: 100%;
}

.box{
    width: 40%;
    height: fit-content;
    border: 1px solid #E0E3E6;
    border-radius: 12px;
    background-color: #ffffff;
  }
  .verificationContainer{
    padding: 24px;
    border-bottom: 1px solid #E0E3E6;
    color: #40454A;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .verification{
    padding: 24px 24px 28px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
  .para{
    color: #999;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    width: 100%;
  }
  .verifyOtpBtn{
        border-radius: 8px;
        border: 1px solid #0084EF;
        background: #0084EF;
        width: 100%;
        padding: 10px 14px;
        color: #ffffff;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
  }
  .otpFlex{
    color: #999;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    display: flex;
  }
  .footerPara{
    padding:24px ;
    font-size:14px;
    line-height:28px;
   }

  @media(max-width:767px){
    .box{
        width:90%;
        margin-top: auto;
    margin-bottom: auto;
    position:fixed;
    }
    .verificationContainer{
        padding:20px;
        font-size:24px;
        line-height: normal;
    }
    .verification{
        padding:20px;
        gap:1.25rem;
    }
    .para{
        font-size:12px;
        line-height: 18px;
    }  
   .verifyOtpBtn{
        padding:14px 10px;
    font-size: 14px;
    }
    .otpFlex{
        flex-direction: column;
        font-size: 12px;
        line-height: 16px;
      }
      .footerPara{
        padding: 16px 20px;
        font-size:12px;
        line-height:16px;
      }
  }